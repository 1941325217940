/**
 * 
 * 
 * :: Set width on all buttons
 * 
 * 
 */

// :: Get all buttons on page



let varBtnModalEls = document.querySelectorAll('.t-p-acf-t2-ct-button-group__button-modal');

// :: observer looking for new links when added to DOM

console.log('varBtnModalEls : ', varBtnModalEls);

varBtnModalEls.forEach(element => {

    // :: Interactive styling attribute settings
    let varButtonDataAttributeHoverColor = element.dataset.buttonHoverColor;

    let varButtonDataAttributeBackgroundColor = element.dataset.buttonBackgroundColor;

    let varButtonDataAttributePostId = element.getAttribute('data-modal-link-post-id');

    let varDataModalType = element.getAttribute('data-modal-type');


    console.log(varDataModalType);
    // :: 'Click' event handler to populate modal wrapper
    element.addEventListener("click", (e) => {

        // alert();

        /**
         * ----
         * 
         * Ajax
         * 
         * ----
         */

        function get_posts($params) {

            // $content   = $container.find('.t-p-footer-modal-target-element');

            let varContent = document.querySelector('.t-p-footer__modal-target-element');

            // console.log('varContent : ', varContent);

            // let content   = document.querySelector('.t-p-footer-modal-settings-target-element');


            // let dataModalLinkId = 

            jQuery.ajax({

                type: 'post',

                dataType: 'json',

                url: scripts_nonce.ajax_url, // url: `${window.location.origin}/wp-admin/admin-ajax.php`,

                data: {

                    action: "my_action",  // <-- the action to fire in the server

                    nonce: scripts_nonce.nonce,

                    params: $params,

                },

                // :: If AJAX response successful 
                success: function (data, textStatus, XMLHttpRequest) {

                    // console.log('data : ', data.content);

                    if (data.status === 200) {

                        // console.log('data : ', data.content);
                        
                        // create div to put innerHTML in
                        let contentInnerHtmlEl = document.createElement('div');

                        contentInnerHtmlEl.classList.add('t-p-footer__modal-target-element-content');

                        if (varDataModalType) {

                            //     contentInnerHtmlEl.classList.add('t-p-footer-modal-target-element-content');

                            varContent.classList.add('change-index');

                        }

                        contentInnerHtmlEl.innerHTML = data.content;

                        varContent.append(contentInnerHtmlEl);

                        varContent.style.opacity = 1; // <-- transition applied in '_scss-t-p-footer.scss'


                        // :: Append modal bgd
                        let modalBgdlEl = document.createElement('div');

                        modalBgdlEl.classList.add('t-p-footer__modal-target-element-content-bgd');

                        modalBgdlEl.innerHTML = '<div class="undefined-modal-bgd"></div>';

                        varContent.append(modalBgdlEl);


                        // console.log('ajax success');
                        // console.log(XMLHttpRequest);
                        console.log(data);
                        console.log(data.content);
                        console.log(textStatus);

                    }


                },

                // :: If AJAX response errors
                error: function (XMLHttpRequest, textStatus, errorThrown) {

                    // $status.html(textStatus);

                    // console.log(textStatus);

                    /*console.log(MLHttpRequest);
                    console.log(textStatus);
                    console.log(errorThrown);*/

                },

                // :: If AJAX response completed
                complete: function (data, textStatus) {

                    // console.log(data);

                    /*console.log(data);
                    console.log(textStatus);*/

                }

            });

        }
        /**
         * -----
         * 
         * /Ajax
         * 
         * =====
         */

        // :: AJAX Parameters
        let $params = {

            'posts_per_page': -1,

            'post_type': 'post',

            'post_ID': varButtonDataAttributePostId,

        };

        // Run query

        // console.log('filter Params', $params);

        get_posts($params);

        // :: Disable standerd click behaviour    
        e.preventDefault();

    });

});



// :: Primary nav 'pricing' click

// :: 'Click' event handler to populate modal wrapper

// const varPrimaryNavPricingEl = document.querySelector('#menu-item-17 a');

// varPrimaryNavPricingEl.addEventListener("click", (e) => {

//     /**
//      * ----
//      * 
//      * Ajax
//      * 
//      * ----
//      */

//     function get_posts($params) {

//         let content = document.querySelector('.t-p-footer__modal-target-element');

//         jQuery.ajax({

//             type: 'post',

//             dataType: 'json',

//             url: scripts_nonce.ajax_url, // url: `${window.location.origin}/wp-admin/admin-ajax.php`,

//             data: {

//                 action: "my_action",  // <-- the action to fire in the server

//                 nonce: scripts_nonce.nonce,

//                 params: $params,

//             },

//             // :: If AJAX response successful 
//             success: function (data, textStatus, XMLHttpRequest) {

//                 // console.log(data.content);

//                 if (data.status === 200) {

//                     // create div to put innerHTML in
//                     let contentInnerHtmlEl = document.createElement('div');

//                     contentInnerHtmlEl.classList.add('t-p-footer__modal-target-element-content');

//                     contentInnerHtmlEl.innerHTML = data.content;

//                     content.append(contentInnerHtmlEl);

//                     content.style.opacity = 1; // <-- transition applied in '_scss-t-p-footer.scss'


//                     // :: Append modal bgd
//                     let modalBgdlEl = document.createElement('div');

//                     modalBgdlEl.classList.add('t-p-footer__modal-target-element-content-bgd');

//                     modalBgdlEl.innerHTML = '<div class="undefined-modal-bgd"></div>';

//                     content.append(modalBgdlEl);


//                     console.log('ajax success');
//                     console.log(XMLHttpRequest);
//                     console.log(data);
//                     console.log(data.content);
//                     console.log(textStatus);

//                 }


//             },

//             // :: If AJAX response errors
//             error: function (XMLHttpRequest, textStatus, errorThrown) {

//                 // $status.html(textStatus);

//                 // console.log(textStatus);

//                 /*console.log(MLHttpRequest);
//                 console.log(textStatus);
//                 console.log(errorThrown);*/

//             },

//             // :: If AJAX response completed
//             complete: function (data, textStatus) {

//                 // console.log(data);

//                 /*console.log(data);
//                 console.log(textStatus);*/

//             }

//         });

//     }
//     /**
//      * -----
//      * 
//      * /Ajax
//      * 
//      * =====
//      */

//     // :: AJAX Parameters
//     let $params = {

//         'posts_per_page': -1,

//         'post_type': 'post',

//         'post_ID': 1392, // <-- post ID for 'CPT Modal - Payment options'

//     };

//     // Run query

//     // console.log('filter Params', $params);

//     get_posts($params);

//     // :: Disable standerd click behaviour    
//     e.preventDefault();

//     // });
// });


// // :: Primary nav 'pricing' click

// // :: 'Click' event handler to populate modal wrapper

// const varFooterNavPricingEl = document.querySelector('#menu-item-3734 a');

// varFooterNavPricingEl.addEventListener("click", (e) => {

//     /**
//      * ----
//      * 
//      * Ajax
//      * 
//      * ----
//      */

//     function get_posts($params) {

//         let content = document.querySelector('.t-p-footer__modal-target-element');

//         jQuery.ajax({

//             type: 'post',

//             dataType: 'json',

//             url: scripts_nonce.ajax_url, // url: `${window.location.origin}/wp-admin/admin-ajax.php`,

//             data: {

//                 action: "my_action",  // <-- the action to fire in the server

//                 nonce: scripts_nonce.nonce,

//                 params: $params,

//             },

//             // :: If AJAX response successful 
//             success: function (data, textStatus, XMLHttpRequest) {

//                 // console.log(data.content);

//                 if (data.status === 200) {

//                     // create div to put innerHTML in
//                     let contentInnerHtmlEl = document.createElement('div');

//                     contentInnerHtmlEl.classList.add('t-p-footer__modal-target-element-content');

//                     contentInnerHtmlEl.innerHTML = data.content;

//                     content.append(contentInnerHtmlEl);

//                     content.style.opacity = 1; // <-- transition applied in '_scss-t-p-footer.scss'


//                     // :: Append modal bgd
//                     let modalBgdlEl = document.createElement('div');

//                     modalBgdlEl.classList.add('t-p-footer__modal-target-element-content-bgd');

//                     modalBgdlEl.innerHTML = '<div class="undefined-modal-bgd"></div>';

//                     content.append(modalBgdlEl);


//                     // console.log('ajax success');
//                     // console.log(XMLHttpRequest);
//                     // console.log(data);
//                     // console.log(data.content);
//                     // console.log(textStatus);

//                 }


//             },

//             // :: If AJAX response errors
//             error: function (XMLHttpRequest, textStatus, errorThrown) {

//                 // $status.html(textStatus);

//                 // console.log(textStatus);

//                 /*console.log(MLHttpRequest);
//                 console.log(textStatus);
//                 console.log(errorThrown);*/

//             },

//             // :: If AJAX response completed
//             complete: function (data, textStatus) {

//                 // console.log(data);

//                 /*console.log(data);
//                 console.log(textStatus);*/

//             }

//         });

//     }
//     /**
//      * -----
//      * 
//      * /Ajax
//      * 
//      * =====
//      */

//     // :: AJAX Parameters
//     let $params = {

//         'posts_per_page': -1,

//         'post_type': 'post',

//         'post_ID': 1392, // <-- post ID for 'CPT Modal - Payment options'

//     };

//     // Run query

//     // console.log('filter Params', $params);

//     get_posts($params);

//     // :: Disable standerd click behaviour    
//     e.preventDefault();

//     // });
// });
