jQuery(document).ready(function($) {

    // Clear search input on page load
    $('#events-search-bar').val('');

    // AJAX search
    $("#events-search-bar").keyup(function() {
        var name = $('#events-search-bar').val().trim();
        var displayEl = $('#display');
        var eventsDisplayEl = $('#events-display');

        // Add or remove class based on input value
        if (name !== "") {
            eventsDisplayEl.addClass('has-content'); // Replace 'your-class-name' with your actual class
        } else {
            eventsDisplayEl.removeClass('has-content');
        }
        

        // Validate if name is empty
        if (name === "") {
            displayEl.html(''); // Empty display if no search term
        } else {
            // Send AJAX request
            $.ajax({
                type: "POST",
                url: scripts_nonce.ajax_url,
                data: {
                    search: name,
                    action: "my_search",
                    nonce: scripts_nonce.nonce,
                },
                success: function(response) {
                    // If results
                    if (response.status === 200) {
                        // Remove inline styles for '#display'
                        displayEl.removeAttr("style");
                        // Add search results
                        displayEl.html(response.content).show();
                    } else {
                        // No results found
                        displayEl.html(response.message);
                    }
                },
                error: function(xhr, status, error) {
                    console.error(xhr.responseText);
                }
            });
        }
    });

});

