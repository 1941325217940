// document.addEventListener('DOMContentLoaded', function () {
//     let buttons = document.querySelectorAll('.product_category_link_parent');
//     let parentCategory = document.querySelector('#parent-categories');
//     let parentSubCategory = document.querySelector('#parent-subcategories');
//     let backButton = document.querySelector('#remove-class-back-button');

//     buttons.forEach(button => {
//         button.addEventListener("click", function (e) {
//             e.preventDefault();

//             // Get category ID from data attribute
//             let categoryId = button.getAttribute('data-category-id');

//             // AJAX Parameters
//             let data = {
//                 action: 'get_subcategories',
//                 nonce: scripts_nonce.nonce,
//                 category_id: categoryId,
//             };

//             // AJAX Call
//             jQuery.ajax({
//                 type: 'POST',
//                 url: scripts_nonce.ajax_url,
//                 data: data,
//                 dataType: 'json',
//                 success: function (response) {
//                     if (response.status === 200) {
//                         // Replace the content of '.sidebar' with subcategories HTML
//                         document.querySelector('#parent-subcategories').innerHTML = response.content;

//                         // Add 'has-content' class to parentCategory and parentSubCategory
//                         parentCategory.classList.add('has-content');
//                         parentSubCategory.classList.add('has-content');
//                     } else {
//                         console.error('Error loading subcategories:', response.message);
//                     }
//                 },
//                 error: function (xhr, textStatus, errorThrown) {
//                     console.error('AJAX Error:', textStatus, errorThrown);
//                 }
//             });
//         });
//     });

//     // Add event listener to backButton
//     backButton.addEventListener("click", function (e) {
//         e.preventDefault();
//         parentCategory.classList.remove('has-content');
//         parentSubCategory.classList.remove('has-content');
//     });
// });

document.addEventListener('DOMContentLoaded', function () {
    let buttons = document.querySelectorAll('.product_category_link_parent');
    let parentCategory = document.querySelector('#parent-categories');
    let parentSubCategory = document.querySelector('#parent-subcategories');
    let parentSubSubCategory = document.querySelector('#parent-products');
    let parentProducts = document.querySelector('#parent-products');
    let backButton = document.querySelector('#remove-class-back-button');

    // First AJAX request when product_category_link_parent is clicked
    buttons.forEach(button => {
        button.addEventListener("click", function (e) {
            e.preventDefault();

            // Get category ID from data attribute
            let categoryId = button.getAttribute('data-category-id');

            // AJAX Parameters
            let data = {
                action: 'get_subcategories',
                nonce: scripts_nonce.nonce,
                category_id: categoryId,
            };

            // AJAX Call for subcategories
            jQuery.ajax({
                type: 'POST',
                url: scripts_nonce.ajax_url,
                data: data,
                dataType: 'json',
                success: function (response) {
                    if (response.status === 200) {
                        // Replace the content of '#parent-subcategories' with subcategories HTML
                        parentSubCategory.innerHTML = response.content;

                        // Add 'has-content' class to parentCategory and parentSubCategory
                        parentCategory.classList.add('has-content');
                        parentSubCategory.classList.add('has-content');

                        // Reset parentProducts content
                        parentProducts.innerHTML = '';
                    } else {
                        console.error('Error loading subcategories:', response.message);
                    }
                },
                error: function (xhr, textStatus, errorThrown) {
                    console.error('AJAX Error:', textStatus, errorThrown);
                }
            });
        });
    });

    // Second AJAX request when product_subcategory_link_parent is clicked
    document.addEventListener('click', function (e) {
        if (e.target && e.target.classList.contains('product_subcategory_link_parent')) {
            e.preventDefault();

            // Get subcategory ID from data attribute
            let subcategoryId = e.target.getAttribute('data-category-id');

            // AJAX Parameters for products
            let data = {
                action: 'get_products_for_category',
                nonce: scripts_nonce.nonce,
                category_id: subcategoryId,
            };

            // AJAX Call for products
            jQuery.ajax({
                type: 'POST',
                url: scripts_nonce.ajax_url,
                data: data,
                dataType: 'json',
                success: function (response) {
                    if (response.success) {
                        // Replace the content of '#parent-products' with products HTML
                        parentSubSubCategory.innerHTML = response.data.content;

                        console.log(response.data.content);
                        // Add 'has-content' class to parentProducts
                        parentSubSubCategory.classList.add('has-content');
                        parentSubCategory.classList.remove('has-content');
                    } else {
                        console.error('Error loading products:', response.data.message);
                    }
                },
                error: function (xhr, textStatus, errorThrown) {
                    console.error('AJAX Error:', textStatus, errorThrown);
                }
            });
        }
    });

    if (backButton) {
    backButton.addEventListener("click", function (e) {
        e.preventDefault();
        parentCategory.classList.remove('has-content');
        parentSubCategory.classList.remove('has-content');
        parentProducts.classList.remove('has-content');
        parentProducts.innerHTML = ''; // Clear the parentProducts container
    });
}
});