jQuery(document).ready(function($) {

    // Function to attach event handlers to input fields
    function attachEventHandlers(formId) {


        console.log(`Attaching event handlers to form: ${formId}`); // Debug statement

        const parentElement = $(`#${formId}`).closest('.t-p-footer__modal-target-element');

        $(parentElement).removeClass('document-preview-modal-parent');

        if (parentElement.length) {
            console.log('Found parent with class t-p-footer__modal-target-element');
    
            // Remove anti-copy/paste attributes if they are present
            parentElement.removeAttr('oncopy onpaste oncut onmousedown oncontextmenu');
            console.log('Removed anti-copy/paste attributes from parent element');
        } else {
            console.log('Parent with class t-p-footer__modal-target-element not found');
        }

        // Detach previous handlers and attach new ones
        $(`#${formId} #user_login, #${formId} #user_pass`).off('input').on('input', function() {
            console.log('Input event triggered'); // Debug statement
            $(`#${formId} .username-error`).hide().text('');
            $(`#${formId} .password-error`).hide().text('');
            $(`#${formId} .general-error`).hide().text('');
        });
    }
    
    // MutationObserver to handle dynamically loaded input fields
    var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            if (mutation.addedNodes.length) {
                $(mutation.addedNodes).each(function() {
                    if (this.nodeType === 1) {
                        console.log('New node added to the DOM'); // Debug statement
                        const $form = $(this).find('#loginform');
                        if ($form.length) {
                            console.log(`Detected #loginform in new DOM content`); // Debug statement
                            // Reset pointer-events on the modal
                            $form.closest('.modal').css('pointer-events', 'auto'); // Adjust selector as needed for your modal
                            attachEventHandlers($form.attr('id'));
                        }
                    }
                });
            }
        });
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });

    // Handle form submission for both forms
    $(document).on('submit', '#loginform, #new_loginform', function(event) {

        event.preventDefault();

        var $form = $(this);

        console.log(`Submitting form: ${$form.attr('id')}`); // Debug statement

        // Collect form data
        var formData = $form.serialize();

        console.log('Form Data:', formData); // Debug statement

        // Clear previous error messages
        $(`#${$form.attr('id')} .username-error`).hide().text('');
        $(`#${$form.attr('id')} .password-error`).hide().text('');
        $(`#${$form.attr('id')} .general-error`).hide().text('');

        $.ajax({
            url: scripts_nonce.ajax_url,
            type: 'POST',
            data: formData + '&action=custom_login',
            dataType: 'json',
            success: function(response) {
                console.log('AJAX Response:', response); // Debug statement

                if (response.success) {
                    window.location.href = response.data.redirect;
                } else {
                    // Hide previous error messages
                    $(`#${$form.attr('id')} #login-errors`).hide();

                    // Handle error messages
                    if (Array.isArray(response.data.error_type)) {
                        response.data.error_type.forEach(function(type) {
                            if (type === 'invalid_email') {
                                $(`#${$form.attr('id')} .general-error`).html('The email address you entered is not registered. Please check and try again.').show();
                            } else if (type === 'invalid_username') {
                                $(`#${$form.attr('id')} .username-error`).html('Invalid email.').show();
                            } else if (type === 'empty_username') {
                                $(`#${$form.attr('id')} .username-error`).html('Your email field is empty.').show();
                            } else if (type === 'incorrect_password') {
                                $(`#${$form.attr('id')} .password-error`).html('Incorrect password.').show();
                            } else if (type === 'empty_password') {
                                $(`#${$form.attr('id')} .password-error`).html('Your password field is empty.').show();
                            } else {
                                $(`#${$form.attr('id')} .general-error`).html(response.data.message || 'An unknown error occurred. Please try again.').show();
                            }
                        });
                    } else {
                        $(`#${$form.attr('id')} .general-error`).html(response.data.message || 'An unknown error occurred. Please try again.').show();
                    }
                }
            },
            error: function(xhr, status, error) {
                console.log('AJAX Error:', error); // Debug statement
                $(`#${$form.attr('id')} .general-error`).text('An unexpected error occurred. Please try again.').show();
            }
        });
    });

    // Attach event handlers for existing forms
    attachEventHandlers('loginform');
});