document.addEventListener("DOMContentLoaded", function () {

    let distributorPortalLeads = document.querySelectorAll(".distributor-portal-container-logged-in-post-container-lead");

    let distributorPortalDisplay = document.getElementById('distributor-portal-display'); // Main display container

    let pageLoadDisplay = document.getElementById("distributor-portal-display-page-load-terms"); // Page load view

    let pageLoadTitleText = document.getElementById("distributor-portal-container-logged-in-page-load-title-text"); // Page load title

    let pageAjaxTitleText = document.getElementById("distributor-portal-container-logged-in-ajax-load-title-text"); // AJAX load title

    let breadcrumbsDistributorPortal = document.getElementById('distributor-portal-breadcrumbs');



    console.log(distributorPortalLeads);

    distributorPortalLeads.forEach(lead => {
        let followElement = lead.querySelector(".distributor-portal-container-logged-in-post-container-follow");

        if (followElement) {
            lead.addEventListener("click", function (event) {
                // Prevent closing when clicking inside the follow section
                if (!event.target.closest(".distributor-portal-container-logged-in-post-container-follow")) {

                    // Close all other open follow elements
                    let isCurrentlyOpen = followElement.classList.contains("open");

                    document.querySelectorAll(".distributor-portal-container-logged-in-post-container-follow.open").forEach(openElement => {
                        openElement.classList.remove("open");
                    });

                    // Toggle current follow section
                    if (!isCurrentlyOpen) {
                        followElement.classList.add("open");

                        // Get the data-term-id from the lead
                        let termId = lead.querySelector(".distributor-portal-container-logged-in-post-container-document")?.getAttribute("data-term-id");

                        if (termId) {
                            console.log("term", termId);
                            fetchAssociatedData("term", termId);
                        }
                    } else {
                        resetToDashboard(); // If all are closed, return to the dashboard
                    }
                }
            });

            // Prevent closing when clicking inside the follow section
            followElement.addEventListener("click", function (event) {
                event.stopPropagation();
            });

            // Handle clicks on follow document links
            let followDocuments = followElement.querySelectorAll(".distributor-portal-container-logged-in-post-container-document-child");

            followDocuments.forEach(doc => {
                doc.addEventListener("click", function (event) {
                    event.preventDefault(); // Prevent default link behavior

                    let postId = doc.getAttribute("data-post-id");

                    if (postId) {
                        fetchAssociatedData("post", postId);
                    }
                });
            });
        }
    });

    /**
     * Resets the display back to the dashboard view.
     */
    function resetToDashboard() {
        console.log("Returning to dashboard view");

        breadcrumbsDistributorPortal.innerHTML = '';

        // Hide the AJAX-loaded section
        distributorPortalDisplay.classList.remove("show");

        // Show the page load view
        pageLoadDisplay.classList.remove("hide");

        // Reset the titles
        pageAjaxTitleText.classList.remove("show");
        pageAjaxTitleText.innerHTML = '';
        pageLoadTitleText.classList.remove("hide");
    }
});



/**
 * Function to fetch data via jQuery AJAX
 * @param {string} type - Either "term" or "post"
 * @param {string} id - The corresponding ID
 */
function fetchAssociatedData(type, id) {

    console.log(`Fetching data for ${type}: ${id}`); // Debugging log



    let distributorPortalDisplay = document.getElementById('distributor-portal-display');

    let pageLoadDisplay = document.getElementById("distributor-portal-display-page-load-terms");

    let pageLoadTitleText = document.getElementById("distributor-portal-container-logged-in-page-load-title-text");

    let pageAjaxTitleText = document.getElementById("distributor-portal-container-logged-in-ajax-load-title-text");

    let breadcrumbsDistributorPortal = document.getElementById('distributor-portal-breadcrumbs');

    jQuery.ajax({
        type: "post",
        dataType: "json",
        url: scripts_nonce.ajax_url, 
        data: {
            action: "fetch_distributor_portal", 
            nonce: scripts_nonce.nonce,
            type: type,
            id: id
        },
        success: function (data, textStatus, XMLHttpRequest) {

            breadcrumbsDistributorPortal.innerHTML = '';

            distributorPortalDisplay.classList.remove('show');

            pageAjaxTitleText.classList.remove('show');

            distributorPortalDisplay.innerHTML = '';

            pageAjaxTitleText.innerHTML = '';
        

                distributorPortalDisplay.classList.add('show');

                distributorPortalDisplay.innerHTML = data.content;

                pageAjaxTitleText.innerHTML = data.title;

                breadcrumbsDistributorPortal.innerHTML = data.breadcrumbs;

                pageLoadDisplay.classList.add('hide');

                pageLoadTitleText.classList.add('hide');

            
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            console.error("AJAX request failed with status:", textStatus, errorThrown);
        },
        complete: function (data, textStatus) {
            console.log("AJAX request completed:", textStatus);
        }
    });
}





document.addEventListener("DOMContentLoaded", function () {

    let searchInput = document.getElementById("search-bar-header-ajax");
    let displayEl = document.getElementById("distributor-portal-display");
    let pageLoadDisplay = document.getElementById("distributor-portal-display-page-load-terms");
    let pageLoadTitleText = document.getElementById("distributor-portal-container-logged-in-page-load-title-text");
    let pageAjaxTitleText = document.getElementById("distributor-portal-container-logged-in-ajax-load-title-text");
    let breadcrumbsDistributorPortal = document.getElementById('distributor-portal-breadcrumbs');

    if (!searchInput) {
        return;
    }

    // Prevent form submission if inside a <form>
    const searchForm = searchInput.closest("form");
    if (searchForm) {
        searchForm.addEventListener("submit", function (event) {
            event.preventDefault(); // Prevent the form from submitting
    
        });
    }

    // Listen for keyup event
    searchInput.addEventListener("keyup", function () {
        let searchQuery = searchInput.value.trim();

        // Validate if search is empty
        if (searchQuery === "") {
            displayEl.innerHTML = ""; 
            displayEl.classList.remove('show'); 
            pageLoadDisplay.classList.remove('hide'); 
            pageLoadTitleText.classList.remove('hide'); 
            pageAjaxTitleText.innerHTML = ''; 
            breadcrumbsDistributorPortal.innerHTML = ''; 
            return;
        }

  
        jQuery.ajax({
            type: "POST",
            url: scripts_nonce.ajax_url, 
            data: {
                search: searchQuery,
                action: "my_search_distributor_portal",
                nonce: scripts_nonce.nonce // Ensure this nonce is passed correctly
            },
            success: function (response) {
                if (response.status === 200) {
                    // Reset elements before showing new results
                    displayEl.innerHTML = '';
                    displayEl.classList.add('show'); 
                    pageLoadDisplay.classList.add('hide'); 
                    pageLoadTitleText.classList.add('hide'); 
                    pageAjaxTitleText.innerHTML = ''; // Clear previous title
                    breadcrumbsDistributorPortal.innerHTML = ''; // Clear breadcrumbs
                    pageAjaxTitleText.innerHTML = 'Search';
                    
                    // Inject new search results
                    displayEl.innerHTML = response.content;

                } else {
                    displayEl.innerHTML = "<p>No results found.</p>"; // Handle empty search results
                }
            },
            error: function (xhr, status, error) {
   
            }
        });
    });

});




document.addEventListener("DOMContentLoaded", function () {

    let distributorPortalDisplay = document.getElementById('distributor-portal-display');
    let pageLoadDisplay = document.getElementById("distributor-portal-display-page-load-terms");
    let pageLoadTitleText = document.getElementById("distributor-portal-container-logged-in-page-load-title-text");
    let pageAjaxTitleText = document.getElementById("distributor-portal-container-logged-in-ajax-load-title-text");
    let breadcrumbsDistributorPortal = document.getElementById('distributor-portal-breadcrumbs');

    console.log("Initializing Page Load AJAX...");

    // Get all page load term links
    let pageLoadTermLinks = document.querySelectorAll(".page-load-term-link");

    if (pageLoadTermLinks.length > 0) {
        console.log(`Found ${pageLoadTermLinks.length} .page-load-term-link elements`);
    }

    pageLoadTermLinks.forEach(termLink => {
        termLink.addEventListener("click", function (event) {
            event.preventDefault(); // Prevent default anchor behavior

            let termId = this.getAttribute("data-term-id");

            if (termId) {
                console.log("Fetching data for term ID:", termId);
                fetchAssociatedData("term" , termId);
            } else {
                console.error("No data-term-id found on clicked element.");
            }
        });
    });

});


document.addEventListener("DOMContentLoaded", function () {
    let clearAjaxPages = document.getElementById('clear-ajax-pages');
    let distributorPortalDisplay = document.getElementById('distributor-portal-display');
    let pageLoadDisplay = document.getElementById("distributor-portal-display-page-load-terms");
    let pageLoadTitleText = document.getElementById("distributor-portal-container-logged-in-page-load-title-text");
    let pageAjaxTitleText = document.getElementById("distributor-portal-container-logged-in-ajax-load-title-text");
    let breadcrumbsDistributorPortal = document.getElementById('distributor-portal-breadcrumbs');

    // Check if the button exists to prevent errors
    if (clearAjaxPages) {
        clearAjaxPages.addEventListener("click", function () {
            // Clear breadcrumbs
            if (breadcrumbsDistributorPortal) {
                breadcrumbsDistributorPortal.innerHTML = '';
            }

            // Hide the AJAX-loaded section
            if (distributorPortalDisplay) {
                distributorPortalDisplay.classList.remove("show");
            }

            // Show the page load view
            if (pageLoadDisplay) {
                pageLoadDisplay.classList.remove("hide");
            }

            // Reset the titles
            if (pageAjaxTitleText) {
                pageAjaxTitleText.classList.remove("show");
                pageAjaxTitleText.innerHTML = '';
            }
            if (pageLoadTitleText) {
                pageLoadTitleText.classList.remove("hide");
            }
        });
    }
});
