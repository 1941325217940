jQuery(document).ready(function($) {
    var debounceTimer; // Timer variable for debounce function

    // Function to handle AJAX search
    function performCalendarSearch(calendarInputValue) {
        // console.log('performCalendarSearch() called with value:', calendarInputValue);
        var displayEl = $('#display');
        var eventsDisplayEl = $('#events-display');

        // Check if calendarInputValue is not empty and contains a valid date range format
        if (calendarInputValue !== "") {


            // Split calendarInputValue into startDate and endDate
            var dateRange = calendarInputValue.split(' — '); // Ensure correct delimiter (' — ')
            
            // console.log('Date Range after split:', dateRange);

            // Check if dateRange contains both startDate and endDate
            if (dateRange.length !== 2) {
                // console.log('Invalid date range format');
                return; // Exit function if date range format is invalid
            }

            var startDate = dateRange[0].trim(); // Ensure to trim whitespace
            var endDate = dateRange[1].trim(); // Ensure to trim whitespace

            // console.log('Start Date:', startDate);
            // console.log('End Date:', endDate);

            // console.log('Sending AJAX request with dates:', startDate, endDate);
            // Send AJAX request
            $.ajax({
                type: "POST",
                url: scripts_nonce.ajax_url,
                data: {
                    start_date: startDate,
                    end_date: endDate,
                    action: "calendar_date_search", // Adjust to your actual AJAX action name
                    nonce: scripts_nonce.nonce,
                },
                success: function(response) {
                    // console.log('AJAX Success Response:', response);
                    // console.log('DEBUG messages:', response.debug_messages);
                    // console.log('queried_dates:', response.queried_dates);
                    // console.log('Submitted Dates pre AJAX:', response.submitted_dates_pre_AJAX);
                    // console.log('Submitted Dates:', response.submitted_dates);
                    // console.log('Events:', response.found);
                    // Handle server response
                    if (response.status === 200) {
                        displayEl.html(response.content).show(); // Display search results
                        eventsDisplayEl.addClass('has-content');
                    } else {
                        displayEl.html(response.message); // Display error or message
                        eventsDisplayEl.addClass('has-content');
                    }
                },
                error: function(xhr, status, error) {
                    console.error('AJAX Error:', xhr.responseText); // Log any errors
                }
            });
        } else {
            // Clear display if calendarInputValue is empty
            displayEl.html('');
            // console.log('Calendar input value is empty');
        }
    }

    // Function to debounce input events
    function debounce(func, delay) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(function() {
            func();
        }, delay);
    }

    // // Initialize MutationObserver to watch for changes in #calendar-input value
    // var observer = new MutationObserver(function(mutations) {
    //     mutations.forEach(function(mutation) {
    //         if (mutation.type === 'attributes' && mutation.attributeName === 'value') {
    //             // console.log('#calendar-input value changed:', mutation.target.value);
    //             var currentCalendarInputValue = mutation.target.value.trim(); // Get current value and trim whitespace

    //             // Perform search with debounce
    //             debounce(function() {
    //                 performCalendarSearch(currentCalendarInputValue);
    //             }, 300); // Adjust debounce delay as needed (in milliseconds)
    //         }
    //     });
    // });

    // // Configuration of the MutationObserver
    // var config = { attributes: true, attributeFilter: ['value'] };

    // // Observe changes in #calendar-input
    // observer.observe(document.getElementById('calendar-input'), config);

    // // Trigger initial search on page load (if needed)
    // performCalendarSearch($('#calendar-input').val().trim()); // This line ensures initial search based on the initial input value
    function initializeObserver() {
        var calendarInput = document.getElementById('calendar-input');
        
        // Check if #calendar-input exists on the page
        if (calendarInput) {
            // Initialize MutationObserver to watch for changes in #calendar-input value
            var observer = new MutationObserver(function(mutations) {
                mutations.forEach(function(mutation) {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'value') {
                        // console.log('#calendar-input value changed:', mutation.target.value);
                        var currentCalendarInputValue = mutation.target.value.trim(); // Get current value and trim whitespace
    
                        // Perform search with debounce
                        debounce(function() {
                            performCalendarSearch(currentCalendarInputValue);
                        }, 300); // Adjust debounce delay as needed (in milliseconds)
                    }
                });
            });
    
            // Configuration of the MutationObserver
            var config = { attributes: true, attributeFilter: ['value'] };
    
            // Observe changes in #calendar-input
            observer.observe(calendarInput, config);
    
            // Trigger initial search on page load (if needed)
            performCalendarSearch(calendarInput.value.trim()); // This line ensures initial search based on the initial input value
        } else {
            console.warn('#calendar-input not found on the page.');
        }
    }
    
    // Call the function to initialize the observer
    initializeObserver();
});




document.addEventListener('DOMContentLoaded', function() {
    const checkbox = document.querySelector('#show-past-events');
    
    function updateEventPastVisibility() {
        const isChecked = checkbox.checked;
        const eventPastDivs = document.querySelectorAll('.event-past');

        eventPastDivs.forEach(div => {
            if (isChecked) {
                div.classList.add('shown');
            } else {
                div.classList.remove('shown');
            }
        });
        console.log('Event past visibility updated:', isChecked ? 'shown' : 'hidden');
    }

    function handleMutation(mutationsList, observer) {
        mutationsList.forEach(mutation => {
            if (mutation.type === 'childList') {
                mutation.addedNodes.forEach(node => {
                    if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('event-categories')) {
                        const eventPastDiv = node.querySelector('.event-past');
                        if (eventPastDiv) {
                            updateEventPastVisibility();
                        }
                    }
                });
            }
        });
    }

    if (checkbox) {
        const observer = new MutationObserver(handleMutation);
        observer.observe(document.body, { childList: true, subtree: true });

        // Initial visibility check on page load
        updateEventPastVisibility();

        checkbox.addEventListener('change', updateEventPastVisibility);
    } else {

    }
});



jQuery(document).ready(function($) {
function clearAllFilters() {
    $('#calendar-input').val('').prop("checked", false);
    $('#events-search-bar').val('');
    $('#show-past-events').val('').prop("checked", false);
    $('#events-display').removeClass('has-content');
    $('.event-categories').addClass('no-content');
    $('.event-past').removeClass('shown');
}

jQuery(document).ready(function($) {
    $('#clear-all-filters-events').on('click', function() {
        clearAllFilters();
    });
});
});