/**
 * 
 * 
 * :: AJAX request for Locations by Product Type
 * 
 * 
 */


// Function to get the selected checkbox IDs and run the AJAX query
// function handleCountryClick(pageId) {
//     console.log('Page ID:', pageId); // Debugging line
//     let params = {
//         page_id: pageId
//     };
//     get_pages_locations(params);
// }

// // Observer function for dynamically added nodes (country groups)
// function checkAddedNodesCountryGroups(addedNodes) {
//     addedNodes.forEach(function(node) {
//         if (node.nodeType === 1) {
//             if (node.matches('g[data-page-id]')) {
//                 let pageId = node.getAttribute('data-page-id');
//                 console.log('Node added with page ID:', pageId); // Debugging line
//                 node.addEventListener('click', function () {
//                     handleCountryClick(pageId);
//                 });
//             }
//             if (node.childNodes.length > 0) {
//                 checkAddedNodesCountryGroups(node.childNodes);
//             }
//         }
//     });
// }

// // Function to run AJAX query with selected parameters
// function get_pages_locations(params) {
//     console.log('AJAX params:', params); // Debugging line
//     let varContent = document.querySelector('.t-p-code__global_locations_modal__input_response');
//     let varMapEl = document.querySelector('.t-p-code__global_locations_modal__map');

//     jQuery.ajax({
//         type: 'page',
//         dataType: 'json',
//         url: scripts_nonce.ajax_url,
//         data: {
//             action: "locations_filter",
//             nonce: scripts_nonce.nonce,
//             params: params,
//         },
//         success: function (data, textStatus, XMLHttpRequest) {
//             if (data.status === 200) {
//                 console.log('Search results:', data.content);
//                 varContent.innerHTML = data.content;

//                 if (data.content && data.content.trim().length > 0) {
//                     varMapEl.classList.add('has-content');
//                 } else {
//                     varMapEl.classList.remove('has-content');
//                 }

//             } else {
//                 varMapEl.classList.remove('has-content');
//             }
//         },
//         error: function (XMLHttpRequest, textStatus, errorThrown) {
//             console.error('AJAX request failed with status:', textStatus, errorThrown);
//         },
//         complete: function (data, textStatus) {
//             console.log('AJAX request completed:', textStatus);
//         }
//     });
// }

// // Create a new MutationObserver instance for country groups
// var mutationObserverCountryGroups = new MutationObserver(function(mutations) {
//     mutations.forEach(function(mutation) {
//         if (mutation.addedNodes.length > 0) {
//             checkAddedNodesCountryGroups(mutation.addedNodes);
//         }
//     });
// });

// // Start observing the document for added country groups
// mutationObserverCountryGroups.observe(document.documentElement, {
//     childList: true,
//     subtree: true
// });

// // Initial event listener setup for elements already in the DOM
// document.addEventListener('DOMContentLoaded', function() {
//     document.querySelectorAll('g[data-page-id]').forEach(function(node) {
//         let pageId = node.getAttribute('data-page-id');
//         console.log('Initial node with page ID:', pageId); // Debugging line
//         node.addEventListener('click', function () {
//             handleCountryClick(pageId);
//         });
//     });
// });

// function handleCountryClick_page(pageId, event) {
//     console.log('Page ID:', pageId); // Debugging line

//     // Example: if the country should have multiple page IDs
//     let pageIds = [pageId];
//     if (pageId === '954') { // Assuming 954 is the ID for the specific country
//         pageIds = [954, 961]; // IDs 954 and 961 for that country
//     }

//     let params = {
//         page_id: pageIds
//     };
//     get_pages_locations_page(params, event);

// function handleCountryClick_page(pageId, event) {
//     console.log('Page ID:', pageId); // Debugging line

//     // Example: if the country should have multiple page IDs
//     let pageIds = [pageId];
//     if (pageId === '954') { // Assuming 954 is the ID for the specific country
//         pageIds = [954, 961]; // IDs 954 and 961 for that country
//     }

//     let params = {
//         page_id: pageIds
//     };
    
//     get_pages_locations_page(params, event);
// }

function handleCountryClick_page(pageId, event) {
    console.log('Page ID:', pageId); // Debugging line

    // Ensure the input is an array of integers
    let pageIds = pageId
        .toString()  // Ensure it's a string for splitting
        .split(',')  // Split by comma if there are multiple IDs
        .map(id => parseInt(id.trim(), 10))  // Convert each to an integer
        .filter(id => !isNaN(id));  // Remove invalid numbers

    let params = {
        page_id: pageIds
    };

    get_pages_locations_page(params, event);
}

function get_pages_locations_page(params, event) {
    console.log('AJAX params:', params); // Debugging line
    let varContent = document.querySelector('.t-p-code__global_locations_modal__input_response_page');
    let varMapEl = document.querySelector('.t-p-code__global_locations_modal__map_page svg');

    if (!varContent || !varMapEl) {
        console.error('Required elements not found');
        return; // Exit function if elements are not found
    }

    jQuery.ajax({
        type: 'post',
        dataType: 'json',
        url: scripts_nonce.ajax_url,
        data: {
            action: "locations_filter_page",
            nonce: scripts_nonce.nonce,
            params: params,
        },
        success: function (data, textStatus, XMLHttpRequest) {
            if (data.status === 200) {
                varContent.innerHTML = data.content;

                // Check if the content is non-empty
                if (data.content && data.content.trim().length > 0) {
                    varMapEl.classList.add('has-content');

                    console.log('AJAX request completed:', textStatus);
                    console.log(data.content);

                    // Position the popover next to the clicked element
                    const mapRect = varMapEl.getBoundingClientRect();
                    const targetRect = event.target.getBoundingClientRect();

                    // Set initial styles
                    varContent.style.position = 'absolute';
                    varContent.style.top = `${targetRect.bottom - mapRect.top}px`;
                    varContent.style.left = `${targetRect.left - mapRect.left}px`;
                    varContent.style.display = 'block'; // Display the popover if there's content

                    // Get viewport dimensions
                    const viewportWidth = window.innerWidth;
                    const viewportHeight = window.innerHeight;

                    // Get content dimensions
                    const contentRect = varContent.getBoundingClientRect();
                    const contentWidth = contentRect.width;
                    const contentHeight = contentRect.height;

                    // Define minimum margins
                    const margin = 40; // Minimum margin from edges

                    // Calculate new positions
                    let newLeft = parseFloat(varContent.style.left);
                    let newTop = parseFloat(varContent.style.top);

                    // Check if the popover is too close to the right edge
                    if (contentRect.right > viewportWidth - margin) {
                        newLeft = viewportWidth - contentWidth - margin;
                    }

                    // Check if the popover is too close to the bottom edge
                    if (contentRect.bottom > viewportHeight - margin) {
                        newTop = viewportHeight - contentHeight - margin;
                    }

                    // Ensure the popover doesn't go beyond the left edge
                    if (newLeft < margin) {
                        newLeft = margin;
                    }

                    // Ensure the popover doesn't go beyond the top edge
                    if (newTop < margin) {
                        newTop = margin;
                    }

                    // Apply the adjusted positions
                    varContent.style.left = `${newLeft}px`;
                    varContent.style.top = `${newTop}px`;

                } else {
                    varMapEl.classList.remove('has-content');
                    varContent.style.display = 'none'; // Hide the popover if there's no content
                }
            } else {
                varContent.innerHTML = "";
                varMapEl.classList.remove('has-content');
                varContent.style.display = 'none'; // Hide the popover in case of an error
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            console.error('AJAX request failed with status:', textStatus, errorThrown);
        },
        complete: function (data, textStatus) {
            console.log('AJAX request completed:', textStatus);
        }
    });
}

// Observer function for dynamically added nodes (country groups)
function checkAddedNodesCountryGroups_page(addedNodes) {
    addedNodes.forEach(function(node) {
        if (node.nodeType === 1) {
            if (node.matches('path[data-page-id]')) {
                let pageId = node.getAttribute('data-page-id');
                console.log('Node added with page ID:', pageId); // Debugging line
                node.addEventListener('click', function (event) {
                    handleCountryClick_page(pageId, event); // Pass page ID and click event
                });
            }
            if (node.childNodes.length > 0) {
                checkAddedNodesCountryGroups_page(node.childNodes);
            }
        }
    });
}

// Create a new MutationObserver instance for country groups
var mutationObserverCountryGroups = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
        if (mutation.addedNodes.length > 0) {
            checkAddedNodesCountryGroups_page(mutation.addedNodes);
        }
    });
});

// Start observing the document for added country groups
mutationObserverCountryGroups.observe(document.documentElement, {
    childList: true,
    subtree: true
});

// Initial event listener setup for elements already in the DOM
document.addEventListener('DOMContentLoaded', function() {
    document.querySelectorAll('path[data-page-id]').forEach(function(node) {
        let pageId = node.getAttribute('data-page-id');
        console.log('Initial node with page ID:', pageId); // Debugging line
        node.addEventListener('click', function (event) {
            handleCountryClick_page(pageId, event); // Pass page ID and click event
        });
    });
});